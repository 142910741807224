/* eslint-disable react/jsx-no-bind */
/* eslint-disable */
/* stylelint-disable */ 

import { PureComponent } from 'react';
import { connect } from 'react-redux';

import history from 'Util/History';
import { CheckoutStepUrl } from 'Route/Checkout/Checkout.config';
import { changeNavigationState } from 'Store/Navigation/Navigation.action';
import { NavigationType } from 'Store/Navigation/Navigation.type';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import MobileSearchField from './MobileSearchField.component';
import { Page } from 'Component/Header/Header.config';

/** @namespace Component/MobileSearchField/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    navigationState: state.NavigationReducer[ NavigationType.TOP_NAVIGATION_TYPE ].navigationState,
    activeOverlay: state.OverlayReducer.activeOverlay,
    device: state.ConfigReducer.device,
});

/** @namespace Component/MobileSearchField/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showOverlay: (overlayKey) => dispatch(toggleOverlayByKey(overlayKey)),
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    setNavigationState: (stateName) => dispatch(changeNavigationState(NavigationType.TOP_NAVIGATION_TYPE, stateName)),
});

export class MobileSearchFieldContainer extends PureComponent {

    state = {
        searchCriteria: ''
    };

    containerProps() {
        const {
            location: {
                pathname,
            },
        } = history;

        const {
            navigationState,
            device
        } = this.props;

        const {
            searchCriteria
        } = this.state;

        const isCheckout = pathname.includes(CheckoutStepUrl.CHECKOUT_URL);
        const isMobile = device.isMobile;

        return {
            navigationState,
            isCheckout,
            searchCriteria,
            isMobile
        }
    }

    containerFunctions = {
        onSearchBarFocus: this.onSearchBarFocus.bind(this),
        onClearSearchButtonClick: this.onClearSearchButtonClick.bind(this),
        onSearchBarChange: this.onSearchBarChange.bind(this),
        onSearchOutsideClick: this.onSearchOutsideClick.bind(this),
        hideActiveOverlay: this.props.hideActiveOverlay
    };

    onSearchOutsideClick() {
        this.hideSearchOverlay();
    }

    hideSearchOverlay() {
        const { hideActiveOverlay } = this.props;

        this.setState({ searchCriteria: '' });

        if (document.activeElement instanceof HTMLElement) {
            document.activeElement.blur();
        }

        hideActiveOverlay();
    }

    onSearchBarFocus() {
        const {
            setNavigationState,
            goToPreviousNavigationState,
            showOverlay,
            navigationState: { name },
            device,
        } = this.props;

        if (
            (!device.isMobile && name === Page.SEARCH)
            || (device.isMobile && name !== Page.MENU)
        ) {
            return;
        }

        showOverlay(Page.SEARCH);

        setNavigationState({
            name: Page.SEARCH,
            onBackClick: () => {
                showOverlay(Page.MENU);
                goToPreviousNavigationState();
            },
        });
    }

    onSearchBarChange(
        { target: { value: searchCriteria } },
    ) {
        this.setState({ searchCriteria });
    }

    onClearSearchButtonClick() {
        this.setState({ searchCriteria: '' });
    }

    render() {
        return (
            <MobileSearchField
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(MobileSearchFieldContainer);