/* eslint-disable react/jsx-no-bind */
/* eslint-disable */
/* stylelint-disable */

import { SearchFieldComponent as SourceSearchFieldComponent } from "SourceComponent/SearchField/SearchField.component";

import SearchOverlay from "Component/SearchOverlay";
import "./SearchField.override.style";

/** @namespace Component/SearchField/Component */
export class SearchFieldComponent extends SourceSearchFieldComponent {
  renderSearch() {

    const { searchCriteria, onSearchBarFocus, isActive } = this.props;

    return (
      <div block="SearchField" elem="SearchInnerWrapper">
        <input
          id="search-field"
          ref={this.searchBarRef}
          block="SearchField"
          elem="Input"
          onFocus={onSearchBarFocus}
          onChange={this.handleChange}
          onKeyDown={this.onSearchEnterPress}
          value={searchCriteria}
          mods={{ isActive }}
          placeholder={__("Search products")}
          autoComplete="off"
          aria-label={__("Search")}
        />
        {this.renderSearchIcon()}
        <SearchOverlay
          isHideOverlay={ true }
          searchCriteria={searchCriteria}
        />
      </div>
    );
  }
}

export default SearchFieldComponent;
